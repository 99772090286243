<template>
  <div class="verify" :class="{noScollForNoLogin_safari:os.safari}">
       <div class="verify-form">
           <h2>欢迎来到享设计</h2>

           <div class="form form_common">
               <p class="cue">验证手机号<i>{{mobile | mobileFilter}}</i>的有效性</p>

               <van-form @submit="onSubmit" ref="v_form">
                    <div class="form_code">
                        <div class="form_code_input">
                            <van-field v-model="yzm" autocomplete="off" maxlength="4" type="number" name="yzm" placeholder="手机验证码" :rules="[{ required: true, message: '请输入手机验证码' }]"/>
                        </div>
                        <div class="btn" @click="onSendCode">{{btnTxt}}</div>
                    </div>

                    <van-field v-model="nickname" autocomplete="off" type="text" name="nickname" placeholder="设置昵称" :rules="[{ required: true, message: '请输入昵称' }]"/>

                    <div class="style2">
                        <van-field v-model="invatation_code" :disabled="codeDisabled" autocomplete="off" type="text" name="invatation_code" placeholder="邀请码（没有可不填）"/>
                    </div>
               </van-form>

                <!-- <div class="verify-agree">
                    <van-checkbox v-model="checked" checked-color="#F85659">注册使用即表明同意<i @click.stop="onPageToAgree">《网站协议》</i>和<i @click.stop="onPageToCopyright">《版权声明》</i></van-checkbox>
                </div> -->

                <c-button @btnClick="onVerify">确定</c-button>
           </div>
       </div>
  </div>
</template>

<script>
import { Field, Form, Checkbox } from "vant";
import CButton from "../../components/form/CButton.vue";
import { mapActions } from "vuex";
import { dialogAlert2 } from "../../lib/util";
import { mapState } from "vuex";
import md5 from "js-md5";
export default {
    components: {
        [CButton.name]: CButton,
        [Field.name]: Field,
        [Form.name]: Form,
        [Checkbox.name]: Checkbox,
    },
    data() {
        return {
            yzm: "",
            nickname: "",
            invatation_code: "",
            checked: true,
            mobile: "",
            password: "",

            canClick: true,
            time: null,
            btnTxt: "获取验证码",
            timeNum: 60,

            md5Code: "",
            resPhone: "",

            codeDisabled: false,
        };
    },
    filters: {
        mobileFilter(val) {
            let reg = /^(.{3}).*(.{4})$/;
            return val.replace(reg, "$1****$2");
        },
    },
    computed: {
        ...mapState({
            fromRouteName: (state) => state.fromRouteName,
            os: (state) => state.os,
        }),
    },
    methods: {
        ...mapActions({
            requestSendCode: "user/requestSendCode",
            requestRegister2: "user/requestRegister2",
            requestLogin: "user/requestLogin",
        }),
        onPageToCopyright() {
            this.$router.push({
                name: "copyright",
            });
        },
        onPageToAgree() {
            this.$router.push({
                name: "agree",
            });
        },
        onSendCode() {
            if (this.canClick) {
                this.canClick = false;
                this.btnTxt = "60s";
                this.intervalTime();
                this.requestSendCode({ mobile: this.mobile }).then((res) => {
                    this.md5Code = res.yzm;
                    this.resPhone = res.mobile;
                });
            }
        },
        onSubmit(e) {
            let _md5Code = md5(e.yzm);

            if (this.mobile != this.resPhone) {
                dialogAlert2("手机号码验证错误");
                return false;
            }

            if (_md5Code != this.md5Code) {
                dialogAlert2("验证码验证错误");
                return false;
            }

            // if (!this.checked) {
            //     dialogAlert2("请勾选同意网站协议和版权声明");
            //     return false;
            // }

            let _params = Object.assign(e, {
                invatation_code: e.invatation_code.toUpperCase(),
                mobile: this.mobile,
                password: this.password,
                step: 2,
            });

            this.requestRegister2(_params).then(() => {
                this.requestLogin({ mobile: this.mobile, password: this.password, }).then(() => {
                    this.initData();    //该页面为缓存页面，需要手动初始化

                    if (this.$cookies.isKey('share_invite_code')) this.$cookies.remove('share_invite_code')  //如果cookies有invite_code，销毁他人邀请码

                    //手机号码注册成功并登陆后，跳回之前页面
                    let _hisPath = sessionStorage.getItem('no_login_route')
                    if (_hisPath == '/products') {
                        this.$router.replace({
                            name: 'products',
                            params: { keywords: this.$store.state.search.historyKeyWords, option: sessionStorage.getItem('option') },
                            query: { keywords: this.$store.state.search.historyKeyWords, option: sessionStorage.getItem('option'), new_reg: 1 }
                        });
                    } else {
                        this.$router.replace({
                            path: _hisPath || '/',
                            query: { new_reg: 1 },
                        });
                    }

                });
            });
        },
        onVerify() {
            this.$refs.v_form.submit();
        },

        intervalTime() {
            this.time = setInterval(() => {
                if (this.timeNum <= 1) {
                    clearInterval(this.time);
                    this.btnTxt = "获取验证码";
                    this.timeNum = 60;
                    this.canClick = true;
                } else {
                    this.timeNum = this.timeNum - 1;
                    this.btnTxt = this.timeNum + "s";
                }
            }, 1000);
        },
        initData() {
            this.yzm = "";
            this.nickname = "";
            this.invatation_code = "";
            this.checked = true;
            this.mobile = "";
            this.password = "";

            this.canClick = true;
            this.time = null;
            this.btnTxt = "获取验证码";
            this.timeNum = 60;

            this.md5Code = "";
            this.resPhone = "";

            this.codeDisabled = false;
        }
    },
    activated() {
        if (this.fromRouteName != 'agree' && this.fromRouteName != 'copyright') {
            let { mobile, password } = this.$route.params;

            if (mobile && password) {
                this.mobile = mobile;
                this.password = password;

                if (this.$cookies.isKey('share_invite_code')) {
                    this.invatation_code = this.$cookies.get('share_invite_code')
                    this.codeDisabled = true;
                }
            } else {
                this.$router.replace({
                    name: "register",
                });
            }
        }
    },
    deactivated() {
    }

};
</script>

<style>
.verify{padding-top: 47px; height: 100vh; background: #fff;}
.verify-form{padding:20px 20px 0;}
.verify-form h2{font-size: 20px;color: #2E2E30; text-align: center; font-weight: normal;}
.verify-form .form{margin-top: 20px;}
.verify-form .form .cue{font-size: 14px;color: #B3B3B3; margin-bottom: 8px;}
.verify-form .form .cue i{font-style: normal; color: #2E2E30; margin:0 5px}
.verify-form .form .style2 .van-field__body{background: #fff; border: 1px solid #B3B3B3;margin-bottom: 20px;}
.verify-agree .van-checkbox__label{font-size: 12px; color: #B3B3B3; margin-left: 6px;}
.verify-agree .van-checkbox__label i{color: #2E2E30; margin:0 2px ; font-style: normal;}
.verify-agree .van-checkbox__icon .van-icon{width:12px; height: 12px; line-height: 12px; font-size: 12px; display: flex; justify-content: center; align-items: center;}
.verify-agree .van-checkbox__icon .van-icon::before{transform: scale(0.8); display: block; width: 12px; height: 12px;}
.verify-agree .van-checkbox__icon{line-height: normal; height: auto;}
.verify-agree{margin-bottom: 32px;}
</style>